<template>
  <div class="date-scope">
    <m-select
      v-if="!hideOp"
      v-model:value="selectedType"
      :items="intervalTypes"
      :read-only="readOnly"
      :disabled="disabled"
      class="_op"
    />
    <m-select
      v-if="hasDynamicValues && !isEmptyType"
      v-model:value="selectedDynamicType"
      :items="dynamicTypes"
      :read-only="readOnly"
      :disabled="disabled"
      class="_op"
    />
    <m-input-number
      v-if="showNumField"
      v-model:value="selectedNumber"
      :read-only="readOnly"
      :disabled="disabled"
      :min="0"
      class="_number"
    />
    <template v-if="showDatePickers">
      <div
        v-if="showDatePicker"
        class="_date-picker-wrapper"
      >
        <m-date-picker
          :value="fromISO(selectedDate)"
          class="_date-picker"
          full-width
          :placeholder="$t('general.empty')"
          :read-only="readOnly"
          :disabled="disabled"
          :date-time="DateTime"
          placement="bottomCenter"
          @input="updateSelectedDate"
        />
      </div>
      <m-date-picker
        v-if="isBetween"
        v-model:value="range"
        :read-only="readOnly"
        :disabled="disabled"
        :date-time="DateTime"
        range
      />
    </template>
  </div>
</template>

<script>
import {
  BETWEEN,
  EMPTY,
  EXACT_DATE,
  GT, LAST_MONTH, LAST_WEEK,
  LAST_X_DAYS,
  LT,
  NEXT_X_DAYS,
  NOT_EMPTY, THIS_MONTH,
  THIS_WEEK,
  TODAY,
} from '@/lib/constants';
import { DateTime } from 'luxon';
import { copy } from 'shared/lib/copy';
import { fromISO, toISO } from 'shared/lib/time';
import { getType } from '@/lib/filter/dynamic-date';
import { userScopeOperator } from 'shared/constants.json';

export default {
  name: 'DateScope',
  props: {
    value: {
      type: Object,
      required: true,
    },
    hasDynamicValues: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideOp: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value', 'change', 'change-not'],
  data() {
    return {
      startDate: null,
      endDate: null,
      selectedDate: null,
      selectedType: LT,
      selectedNumber: 7,
      selectedDynamicType: EXACT_DATE,
      DateTime,
      fromISO,
    };
  },
  computed: {
    intervalTypes() {
      if (this.hasDynamicValues) {
        return [
          { text: this.$t('dateScope.isBefore'), value: LT },
          { text: this.$t('dateScope.isAfter'), value: GT },
          { text: this.$t('dateScope.isBetween'), value: BETWEEN },
          { text: this.$t('dateScope.isEmpty'), value: EMPTY },
          { text: this.$t('dateScope.isNotEmpty'), value: NOT_EMPTY },
        ];
      }
      return [
        { text: this.$t('dateScope.isBefore'), value: LT },
        { text: this.$t('dateScope.isAfter'), value: GT },
        { text: this.$t('dateScope.isBetween'), value: BETWEEN },
      ];
    },
    dynamicTypes() {
      if (this.isBetween) {
        return [
          { text: this.$t('dateScope.today'), value: TODAY },
          { text: this.$t('dateScope.nextXDays'), value: NEXT_X_DAYS },
          { text: this.$t('dateScope.lastXDays'), value: LAST_X_DAYS },
          { text: this.$t('dateScope.thisWeek'), value: THIS_WEEK },
          { text: this.$t('dateScope.lastWeek'), value: LAST_WEEK },
          { text: this.$t('dateScope.thisMonth'), value: THIS_MONTH },
          { text: this.$t('dateScope.lastMonth'), value: LAST_MONTH },
          { text: this.$t('dateScope.dateRange'), value: EXACT_DATE },
        ];
      }

      return [
        { text: this.$t('dateScope.today'), value: TODAY },
        { text: this.$t('dateScope.xDaysFromNow'), value: NEXT_X_DAYS },
        { text: this.$t('dateScope.xDaysAgo'), value: LAST_X_DAYS },
        { text: this.$t('dateScope.beginningThisWeek'), value: THIS_WEEK },
        { text: this.$t('dateScope.beginningLastWeek'), value: LAST_WEEK },
        { text: this.$t('dateScope.beginningThisMonth'), value: THIS_MONTH },
        { text: this.$t('dateScope.beginningLastMonth'), value: LAST_MONTH },
        { text: this.$t('dateScope.exactDate'), value: EXACT_DATE },
      ];
    },
    range: {
      get() {
        return { startDate: this.startDate, endDate: this.endDate };
      },
      set({ startDate, endDate }) {
        this.startDate = startDate;
        this.endDate = endDate;
      },
    },
    scope() {
      return {
        ...this.value,
        timeRange: this.timeRange,
      };
    },
    timeRange() {
      const { start, end } = this.rangeByTypes(this.selectedType, this.selectedDynamicType);
      return {
        start,
        end,
        type: this.selectedType,
        dynamicType: this.selectedDynamicType,
        amount: this.selectedNumber,
      };
    },
    isBetween() {
      return this.selectedType === BETWEEN;
    },
    isEmptyType() {
      return [EMPTY, NOT_EMPTY].includes(this.selectedType);
    },
    showDatePickers() {
      if (!this.hasDynamicValues) {
        return !this.isEmptyType;
      }
      return this.selectedDynamicType === EXACT_DATE && !this.isEmptyType;
    },
    showNumField() {
      return [LAST_X_DAYS, NEXT_X_DAYS].includes(this.selectedDynamicType) && !this.isEmptyType;
    },
    showDatePicker() {
      return [GT, LT].includes(this.selectedType);
    },
  },
  methods: {
    updateSelectedDate(date) {
      this.selectedDate = toISO(date);
    },
    rangeByTypes(type, dynamicType) {
      if (dynamicType !== EXACT_DATE) {
        return { start: null, end: null };
      }
      switch (type) {
        case LT:
          return { start: null, end: DateTime.fromISO(this.selectedDate).startOf('day') };
        case GT:
          return { start: DateTime.fromISO(this.selectedDate).endOf('day'), end: null };
        case BETWEEN:
          return { start: DateTime.fromISO(this.startDate).toISO(), end: DateTime.fromISO(this.endDate).toISO() };
        default:
          return { start: null, end: null };
      }
    },
    toOutputFormat(dateTime) {
      return dateTime.startOf('second').toISO({ suppressMilliseconds: true });
    },
    init() {
      this.selectedDate = DateTime.local().toISO();
      this.selectedType = LT;
      this.selectedDynamicType = EXACT_DATE;
      this.selectedNumber = 7;
      if (this.hasDynamicValues) {
        this.selectedType = BETWEEN;
        this.selectedDynamicType = LAST_X_DAYS;
      }
      this.emitUpdate(this.scope);
    },
    setValues(timeRange) {
      const type = getType(timeRange);
      this.selectedType = type;

      if (type === BETWEEN) {
        this.startDate = DateTime.fromISO(timeRange.start).toISODate();
        this.endDate = DateTime.fromISO(timeRange.end).toISODate();
      }
      if (type === LT) {
        this.selectedDate = timeRange.end;
      }
      if (type === GT) {
        this.selectedDate = timeRange.start;
      }

      if (this.hasDynamicValues) {
        this.selectedNumber = timeRange.amount;
        this.selectedDynamicType = timeRange.dynamicType;
      }
    },
    updateValues() {
      if (this.value === null
        || typeof this.value.timeRange === 'undefined'
        || this.value.timeRange === null
      ) {
        this.init();
        return;
      }
      this.setValues(this.value.timeRange);
    },
    emitUpdate(val) {
      this.$emit('input', val);
      this.$emit('update:value', val);
      this.$emit('change', val);
      this.$emit('change-not', userScopeOperator.and);
    },
  },
  watch: {
    scope: {
      handler(newVal, oldVal) {
        if (JSON.stringify(oldVal) === JSON.stringify(newVal)) {
          return;
        }

        const cp = copy(newVal);
        this.emitUpdate(cp);
      },
      deep: true,
    },
    value: {
      handler() {
        this.updateValues();
      },
      deep: true,
    },
  },
  created() {
    this.updateValues();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .date-scope {
    display: flex;

    ._op {
      margin-right: .4rem;
    }

    ._date-picker-wrapper {
      ._date-picker {
        min-width: 13rem;
      }
    }

    ._start {
      margin-right: .4rem;
    }

    ._number {
      width: 6rem;
    }

    ._and {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 .8rem;
      margin-right: .4rem;
    }
  }
</style>
